import React, { useEffect, useState } from "react";
import Loader from "../layout/Loader";
import { toast } from "react-hot-toast";
import MetaData from "../layout/MetaData";
import AdminLayout from "../layout/AdminLayout";
import { useNavigate } from "react-router-dom";
import { PRODUCT_CATEGORIES } from "../../constants/constants";
import { useCreateProductMutation } from "../../redux/api/productsApi";

const NewProduct = () => {
  const navigate = useNavigate();

  const [product, setProduct] = useState({
    name: "",
    price: "",
    sizeSmall: "",
    sizeMedium: "",
    sizeQuarterSlab: "",
    sizeHalfSlab: "",
    sizeFullSlab: "",
    serv1: "",
    serv2: "",
    serv3: "",
    serv4: "",
    flavourChocolate: "",
    flavourBlackForest: "",
    flavourRedVelvet: "",
    flavourFruit: "",
    flavourVanilla: "",
    flavourPineapple: "",
    flavourMango: "",
    flavourStrawberry: "",
    baseRegular: "",
    baseGlutenFree: "",
    baseVeganGlutenFree: "",
    colour: "",
    recipecode: "",
    description: "",
    category: "",
  });

  const [createProduct, { isLoading, error, isSuccess }] = useCreateProductMutation();

  useEffect(() => {
    if (error) {
      toast.error(error.data.message);
    }
    if (isSuccess) {
      toast.success("Product created successfully");
      navigate("/admin/products");
    }
  }, [error, isSuccess, navigate]);

  const onChange = (e) => {
    setProduct({ ...product, [e.target.name]: e.target.value });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    createProduct(product);
  };

  const {
    name,
    price,
    sizeSmall,
    sizeMedium,
    sizeQuarterSlab,
    sizeHalfSlab,
    sizeFullSlab,
    serv1,
    serv2,
    serv3,
    serv4,
    flavourChocolate,
    flavourBlackForest,
    flavourRedVelvet,
    flavourFruit,
    flavourVanilla,
    flavourPineapple,
    flavourMango,
    flavourStrawberry,
    baseRegular,
    baseGlutenFree,
    baseVeganGlutenFree,
    colour,
    recipecode,
    description,
    category,
  } = product;

  return (
    <AdminLayout>
      <MetaData title={"Create new product"} />
      <div className="row wrapper">
        <div className="col-10 col-lg-10 mt-5 mt-lg-0">
          <form className="shadow rounded bg-body" onSubmit={submitHandler}>
            <h2 className="mb-4">New Product</h2>

            <div className="mb-3">
              <label htmlFor="name_field" className="form-label">Name</label>
              <input type="text" id="name_field" className="form-control" name="name" value={name} onChange={onChange} />
            </div>

            <div className="mb-3">
              <label htmlFor="description_field" className="form-label">Description</label>
              <textarea className="form-control" id="description_field" rows="8" name="description" value={description} onChange={onChange}></textarea>
            </div>

            <div className="mb-3">
              <label htmlFor="price_field" className="form-label">Price</label>
              <input type="number" id="price_field" className="form-control" name="price" value={price} onChange={onChange} />
            </div>

            <div className="row">
              <div className="mb-3 col">
                <label htmlFor="sizeSmall_field" className="form-label">6" - Round (servings 4-6 ppl)</label>
                <input type="text" id="sizeSmall_field" className="form-control" name="sizeSmall" value={sizeSmall} onChange={onChange} />
              </div>
              <div className="mb-3 col">
                <label htmlFor="sizeMedium_field" className="form-label">6" - Tall Round (servings 10-12)</label>
                <input type="text" id="sizeMedium_field" className="form-control" name="sizeMedium" value={sizeMedium} onChange={onChange} />
              </div>
              <div className="mb-3 col">
                <label htmlFor="sizeQuarterSlab_field" className="form-label">8" - Round (servings 10-12)</label>
                <input type="text" id="sizeQuarterSlab_field" className="form-control" name="sizeQuarterSlab" value={sizeQuarterSlab} onChange={onChange} />
              </div>
              <div className="mb-3 col">
                <label htmlFor="sizeHalfSlab_field" className="form-label">8" - Tall Round (servings 18-20)</label>
                <input type="text" id="sizeHalfSlab_field" className="form-control" name="sizeHalfSlab" value={sizeHalfSlab} onChange={onChange} />
              </div>
              <div className="mb-3 col">
                <label htmlFor="sizeFullSlab_field" className="form-label">Quarter - 8"x12" (servings 18-20)</label>
                <input type="text" id="sizeFullSlab_field" className="form-control" name="sizeFullSlab" value={sizeFullSlab} onChange={onChange} />
              </div>
            </div>

            <div className="row">
              <div className="mb-3 col">
                <label htmlFor="serv1_field" className="form-label">Half Slab - 12"x15" (servings 45-55)</label>
                <input type="number" id="serv1_field" className="form-control" name="serv1" value={serv1} onChange={onChange} min="0" />
              </div>
              <div className="mb-3 col">
                <label htmlFor="serv2_field" className="form-label">Full Slab - 16"x24 (servings 90-100)</label>
                <input type="number" id="serv2_field" className="form-control" name="serv2" value={serv2} onChange={onChange} min="0" />
              </div>
              <div className="mb-3 col">
                <label htmlFor="serv3_field" className="form-label">Servings 10-15</label>
                <input type="number" id="serv3_field" className="form-control" name="serv3" value={serv3} onChange={onChange} min="0" />
              </div>
              <div className="mb-3 col">
                <label htmlFor="serv4_field" className="form-label">Servings 20-25</label>
                <input type="number" id="serv4_field" className="form-control" name="serv4" value={serv4} onChange={onChange} min="0" />
              </div>
            </div>

            <div className="row">
              <div className="mb-3 col">
                <label htmlFor="flavourChocolate_field" className="form-label">Chocolate</label>
                <input type="text" id="flavourChocolate_field" className="form-control" name="flavourChocolate" value={flavourChocolate} onChange={onChange} />
              </div>
              <div className="mb-3 col">
                <label htmlFor="flavourBlackForest_field" className="form-label">Black Forest</label>
                <input type="text" id="flavourBlackForest_field" className="form-control" name="flavourBlackForest" value={flavourBlackForest} onChange={onChange} />
              </div>
              <div className="mb-3 col">
                <label htmlFor="flavourRedVelvet_field" className="form-label">Red Velvet</label>
                <input type="text" id="flavourRedVelvet_field" className="form-control" name="flavourRedVelvet" value={flavourRedVelvet} onChange={onChange} />
              </div>
              <div className="mb-3 col">
                <label htmlFor="flavourFruit_field" className="form-label">Fruit</label>
                <input type="text" id="flavourFruit_field" className="form-control" name="flavourFruit" value={flavourFruit} onChange={onChange} />
              </div>
            </div>

            <div className="row">
              <div className="mb-3 col">
                <label htmlFor="flavourVanilla_field" className="form-label">Vanilla</label>
                <input type="text" id="flavourVanilla_field" className="form-control" name="flavourVanilla" value={flavourVanilla} onChange={onChange} />
              </div>
              <div className="mb-3 col">
                <label htmlFor="flavourPineapple_field" className="form-label">Pineapple</label>
                <input type="text" id="flavourPineapple_field" className="form-control" name="flavourPineapple" value={flavourPineapple} onChange={onChange} />
              </div>
              <div className="mb-3 col">
                <label htmlFor="flavourMango_field" className="form-label">Mango</label>
                <input type="text" id="flavourMango_field" className="form-control" name="flavourMango" value={flavourMango} onChange={onChange} />
              </div>
              <div className="mb-3 col">
                <label htmlFor="flavourStrawberry_field" className="form-label">Strawberry</label>
                <input type="text" id="flavourStrawberry_field" className="form-control" name="flavourStrawberry" value={flavourStrawberry} onChange={onChange} />
              </div>
            </div>

            <div className="row">
              <div className="mb-3 col">
                <label htmlFor="baseRegular_field" className="form-label">Regular Base</label>
                <input type="text" id="baseRegular_field" className="form-control" name="baseRegular" value={baseRegular} onChange={onChange} />
              </div>
              <div className="mb-3 col">
                <label htmlFor="baseGlutenFree_field" className="form-label">Gluten-Free Base</label>
                <input type="text" id="baseGlutenFree_field" className="form-control" name="baseGlutenFree" value={baseGlutenFree} onChange={onChange} />
              </div>
              <div className="mb-3 col">
                <label htmlFor="baseVeganGlutenFree_field" className="form-label">Vegan Gluten-Free Base</label>
                <input type="text" id="baseVeganGlutenFree_field" className="form-control" name="baseVeganGlutenFree" value={baseVeganGlutenFree} onChange={onChange} />
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="colour_field" className="form-label">Colour</label>
              <input type="text" id="colour_field" className="form-control" name="colour" value={colour} onChange={onChange} />
            </div>

            <div className="mb-3">
              <label htmlFor="recipecode_field" className="form-label">Product Code</label>
              <input type="text" id="recipecode_field" className="form-control" name="recipecode" value={recipecode} onChange={onChange} />
            </div>

            <div className="mb-3">
              <label htmlFor="category_field" className="form-label">Category</label>
              <select id="category_field" className="form-control" name="category" value={category} onChange={onChange}>
                <option value="">Choose Category</option>
                {PRODUCT_CATEGORIES.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>

            <button id="login_button" type="submit" className="btn btn-primary py-2 w-100" disabled={isLoading}>
              {isLoading ? <Loader /> : "CREATE"}
            </button>
          </form>
        </div>
      </div>
    </AdminLayout>
  );
};

export default NewProduct;