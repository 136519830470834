import React, { useEffect, useState } from 'react';
import StarRatings from 'react-star-ratings';
import { useSubmitReviewMutation, useCanUserReviewQuery } from '../../redux/api/productsApi';
import toast from 'react-hot-toast';

const NewReview = ({ productId }) => {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [error, setError] = useState(null);

  const [submitReview, { isLoading, error: apiError, isSuccess }] = useSubmitReviewMutation();
  const { data } = useCanUserReviewQuery(productId);
  const canReview = data?.canReview;

  useEffect(() => {
    if (apiError) {
      setError(apiError?.data?.message || "An error occurred while submitting your review.");
      toast.error(error);
    }
    if (isSuccess) {
      toast.success("Review submitted successfully");
      setRating(0); // Reset rating
      setComment(""); // Reset comment
    }
  }, [apiError, isSuccess, error]);

  const submitHandler = () => {
    if (rating === 0) {
      setError("Please select a rating");
      return;
    }
    if (comment.trim() === "") {
      setError("Please enter a comment");
      return;
    }
    const reviewData = { rating, comment, productId };
    submitReview(reviewData);
  };

  return (
    <div>
      {canReview && (
        <button
          id="review_btn"
          type="button"
          className="btn btn-primary mt-4"
          data-bs-toggle="modal"
          data-bs-target="#ratingModal"
        >
          Submit Your Review
        </button>
      )}
      <div className="modal fade" id="ratingModal" tabIndex="-1" role="dialog" aria-labelledby="ratingModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="ratingModalLabel">Submit Review</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <StarRatings
                rating={rating}
                starRatedColor="#ffb829"
                numberOfStars={5}
                name="rating"
                changeRating={(newRating) => setRating(newRating)}
              />

              <textarea
                name="review"
                id="review"
                className="form-control mt-4"
                placeholder="Enter your comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              ></textarea>

              {error && <div style={{ color: 'red' }}>{error}</div>}

              <button
                id="new_review_btn"
                className="btn w-100 my-4 px-4"
                data-bs-dismiss="modal"
                onClick={submitHandler}
                disabled={isLoading}
              >
                {isLoading ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewReview;
