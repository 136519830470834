import React, { useState, useEffect } from 'react';
import AdminLayout from '../layout/AdminLayout';
import Loader from "../layout/Loader";
import { toast } from "react-hot-toast";
import { MDBDataTable } from 'mdbreact';
import { Link } from 'react-router-dom';
import '../../App.css'; // Import the global CSS file
import MetaData from "../layout/MetaData";
import { useLazyGetProductReviewsQuery, useDeleteReviewMutation } from "../../redux/api/productsApi";

const ProductReviews = () => {
    const [productId, setProductId] = useState("");
    const [reviews, setReviews] = useState({ columns: [], rows: [] });

    const [getProductReviews, { data, isLoading, error }] = useLazyGetProductReviewsQuery();
    const [deleteReview, { error: deleteError, isLoading: isDeleteLoading, isSuccess}] = useDeleteReviewMutation();

    useEffect(() => {
        if (error) {
            toast.error(error?.data?.message || "An error occurred while fetching reviews.");
        }
        if (deleteError) {
            toast.error(deleteError?.data?.message || "An error occurred while deleting the review.");
        }
        if (isSuccess) {
            toast.success('Review deleted successfully');
        }
    }, [error, deleteError, isSuccess]);

    useEffect(() => {
        if (data) {
            const columns = [
                { label: "Review ID", field: "id", sort: "asc" },
                { label: "Rating", field: "rating", sort: "asc" },
                { label: "Comment", field: "comment", sort: "asc" },
                { label: "User", field: "user", sort: "asc" },
                { label: "Actions", field: "actions" } // Added Actions column
            ];

            if (data.reviews.length === 0) {
                toast.error("No reviews found for the entered product ID.");
            } else {
                const rows = data.reviews.map((review) => {
                    console.log("User:", review?.user?.name); // Log user name here
                    return {
                        id: review?._id,
                        rating: review?.rating,
                        comment: review?.comment,
                        user: review?.user,
                        actions: (
                            <div className="d-flex align-items-center">
                                <button className="btn btn-outline-danger me-2" 
                                    onClick={() => deleteReviewHandler(review?._id)}
                                    disabled={isDeleteLoading}>
                                    <i className="fa fa-trash"></i>
                                </button>
                            </div>
                        ),
                    };
                });
                setReviews({ columns, rows });
            }
        }
    }, [data]);

    const submitHandler = (e) => {
        e.preventDefault();
        if (productId.trim()) {
            getProductReviews(productId);
        } else {
            toast.error("Product ID cannot be empty.");
        }
    }

    const deleteReviewHandler = (id) => {
        const payload = { productId, reviewId: id };
        console.log("Sending to backend:", payload);
        deleteReview(payload);
    }

    if (isLoading) return <Loader />;

    return (
        <AdminLayout>
            <div className="row justify-content-center my-5">
                <div className="col-6">
                    <form onSubmit={submitHandler}>
                        <div className="mb-3">
                            <label htmlFor="productId_field" className="form-label">
                                Enter Product ID
                            </label>
                            <input
                                type="text"
                                id="productId_field"
                                className="form-control"
                                value={productId}
                                onChange={(e) => setProductId(e.target.value)}
                            />
                        </div>

                        <button
                            id="search_button"
                            type="submit"
                            className="btn btn-primary w-100 py-2"
                        >
                            SEARCH
                        </button>
                    </form>
                </div>
            </div>

            <MDBDataTable
                data={reviews}
                className="px-3"
                bordered
                striped
                hover
            />
        </AdminLayout>
    );
}

export default ProductReviews;
