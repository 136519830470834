import React from 'react';
import { Link } from "react-router-dom";
import StarRatings from "react-star-ratings";
import { Helmet } from "react-helmet";

const ProductItem = ({ product, columnSize }) => {
    // Helper function to ensure HTTPS URLs
    const ensureHttps = (url) => {
        if (typeof url !== 'string') return url;
        return url.replace(/^http:\/\//i, 'https://');
    };

    const structuredData = {
        "@context": "https://schema.org/",
        "@type": "Product",
        "name": product?.name,
        "image": [
            ensureHttps(product?.images[0]?.url) || "/images/default_product.png"
        ],
        "description": product?.description,
        "sku": product?._id,
        "mpn": product?._id,
        "brand": {
            "@type": "Brand",
            "name": "Vegetarian Delite Bakery"
        },
        "offers": {
            "@type": "Offer",
            "url": `${window.location.origin}/product/${product?._id}`,
            "priceCurrency": "CAD",
            "price": product?.price,
            "availability": "https://schema.org/InStock",
            "seller": {
                "@type": "Organization",
                "name": "Vegetarian Delite Bakery",
                "areaServed": {
                    "@type": "City",
                    "name": "Vancouver"
                }
            }
        },
        "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": product?.ratings,
            "reviewCount": product?.numberOfReviews
        }
    };

    // Generate keywords based on product properties and location
    const keywords = `${product?.name}, vegetarian cake, Vancouver bakery, ${product?.category}, ${product?.flavor || ''}, ${product?.occasion || ''}, Vegetarian Delite Bakery`.toLowerCase();

    return (
        <>
            <Helmet>
                <title>{`${product?.name} | Vegetarian Delite Bakery, Vancouver`}</title>
                <meta name="description" content={`${product?.description || `Delicious vegetarian ${product?.name}`} from Vegetarian Delite Bakery in Vancouver, Canada. Order now for local pickup or delivery!`} />
                <meta name="keywords" content={keywords} />
                <meta property="og:title" content={`${product?.name} | Vegetarian Delite Bakery, Vancouver`} />
                <meta property="og:description" content={`${product?.description || `Delicious vegetarian ${product?.name}`} from Vegetarian Delite Bakery in Vancouver, Canada. Order now!`} />
                <meta property="og:image" content={ensureHttps(product?.images[0]?.url) || "/images/default_product.png"} />
                <meta property="og:url" content={`${window.location.origin}/product/${product?._id}`} />
                <meta property="og:type" content="product" />
                <meta property="og:locale" content="en_CA" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="geo.region" content="CA-BC" />
                <meta name="geo.placename" content="Vancouver" />
                <link rel="canonical" href={`${window.location.origin}/product/${product?._id}`} />
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </Helmet>
            <div className={`col-sm-12 col-md-6 col-lg-${columnSize} my-3`}>
                <div className="card p-3 rounded">
                    <img
                        className="card-img-top mx-auto"
                        src={ensureHttps(product?.images[0]?.url) || "/images/default_product.png"}
                        alt={`${product?.name} - Vegetarian cake from Vegetarian Delite Bakery, Vancouver`}
                    />
                    <div className="card-body ps-3 d-flex justify-content-center flex-column">
                        <h5 className="card-title">
                            <Link to={`/product/${product?._id}`}>{product?.name}</Link>
                        </h5>
                        <div className="ratings mt-auto d-flex">
                            <StarRatings
                                rating={product?.ratings}
                                starRatedColor="#ffb829"
                                numberOfStars={5}
                                name="rating"
                                starDimension="22px"
                                starSpacing="1px"
                            />
                            <span id="no_of_reviews" className="pt-2 ps-2">
                                {" "}
                                ({product?.numberOfReviews})
                            </span>
                        </div>
                        <p className="card-text mt-2">${product?.price}</p>
                        <Link to={`/product/${product?._id}`} id="view_btn" className="btn btn-block">
                            View Details
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductItem;