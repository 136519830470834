import { useSearchParams, useNavigate } from "react-router-dom";
import Pagination from 'react-js-pagination';  // Ensure correct package import
import React, { useState, useEffect } from 'react';


const CustomPagination = ({ resPerPage  = 20, filtererdProductsCount }) => {
  const [currentPage, setCurrentPage] = useState(); 
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();

  //const [searchParams, setSearchParams] = useSearchParams();
  
  // Retrieve current page from URL or default to 1
  const page = Number(searchParams.get('page')) || 1;

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  
  const setCurrentPageNo = (pageNumber) => {
    setCurrentPage(pageNumber);

    // Update URL with the new page number
    if (searchParams.has("page")) {
        searchParams.set("page", pageNumber);
    } else {
        searchParams.append("page", pageNumber);
    }

    // Construct the new path and alert it
    const path = window.location.pathname + "?" + searchParams.toString();
  
    navigate(path); // Corrected this line
};

// Log filtererdProductsCount to the console
console.log('Filtered Products Count:', filtererdProductsCount);
console.log('Results Per Page:', resPerPage);

return (
    <div className="d-flex justify-content-center my-5">
      {filtererdProductsCount > resPerPage && (
        <Pagination
          activePage={currentPage}
          itemsCountPerPage={resPerPage}
          totalItemsCount={filtererdProductsCount}
          onChange={setCurrentPageNo}
          nextPageText={"Next"}
          prevPageText={"Prev"}
          firstPageText={"First"}
          lastPageText={"Last"}
          itemClass="page-item"
          linkClass="page-link"
        />
      )}
    </div>
  );
  
};

export default CustomPagination;
