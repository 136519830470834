import React, { useEffect, useState } from "react";
import Loader from "../layout/Loader";
import { toast } from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import '../../App.css'; // Import the global CSS file
import MetaData from "../layout/MetaData";
import AdminLayout from "../layout/AdminLayout";
import { useOrderDetailsQuery, useUpdateOrderMutation } from "../../redux/api/orderApi";

const ProcessOrder = () => {
    const [status, setStatus] = useState("");
    const [cakeBakedBy, setCakeBakedBy] = useState("");
    const [cakeBakedAt, setCakeBakedAt] = useState("");
    const [cakeDeliveredBy, setCakeDeliveredBy] = useState("");
    const [cakeDeliveredAt, setCakeDeliveredAt] = useState("");
    const params = useParams();
    const { data, isLoading, error } = useOrderDetailsQuery(params?.id);
    const [updateOrder, { error: updateError, isSuccess }] = useUpdateOrderMutation();

    const order = data?.order || {};
    const { shippingInfo, orderItems, paymentInfo, user, totalAmount, orderStatus } = order;

    const isPaid = paymentInfo?.status === "paid" ? true : false;

    useEffect(() => {
        if (orderStatus) {
            setStatus(orderStatus);
        }
        // Set existing values for new fields
        if (order) {
            setCakeBakedBy(order.cakeBakedBy || "");
            setCakeBakedAt(order.cakeBakedAt || "");
            setCakeDeliveredBy(order.cakeDeliveredBy || "");
            setCakeDeliveredAt(order.cakeDeliveredAt || "");
        }
    }, [order, orderStatus]);

    useEffect(() => {
        if (error) {
            toast.error(error?.data?.message);
        }
        if (updateError) {
            toast.error(updateError?.data?.message);
        }
        if (isSuccess) {
            toast.success("Order updated successfully");
        }
    }, [error, updateError, isSuccess]);

    const updateOrderHandler = (id) => {
        const data = {
            orderStatus: status,
            cakeBakedBy,
            cakeBakedAt,
            cakeDeliveredBy,
            cakeDeliveredAt
        };
        console.log("Update Order Data:", data);
        updateOrder({ id, body: data });
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <AdminLayout>
            <MetaData title="Process Order" />

            <div className="row d-flex justify-content-around">
                <div className="col-12 col-lg-8 order-details">
                    <h3 className="mt-5 mb-4">Order Details</h3>
                    <table className="table table-striped table-bordered">
                        <tbody>
                            <tr>
                                <th scope="row">ID</th>
                                <td>{order?.pid}</td>
                            </tr>
                            <tr>
                                <th scope="row">Delivery Date</th>
                                <td>{shippingInfo?.deliveryDate}</td>
                            </tr>
                            <tr>
                                <th scope="row">Delivery Time</th>
                                <td>{shippingInfo?.time}</td>
                            </tr>
                            <tr>
                                <th scope="row">Order Status</th>
                                <td className={String(orderStatus).includes("Delivered") ? "greenColor" : "redColor"}>
                                    <b>{orderStatus}</b>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <h3 className="mt-5 mb-4">Shipping Info</h3>
                    <table className="table table-striped table-bordered">
                        <tbody>
                            <tr>
                                <th scope="row">Name</th>
                                <td>{user?.name}</td>
                            </tr>
                            <tr>
                                <th scope="row">Phone </th>
                                <td>{shippingInfo?.phoneNo}</td>
                            </tr>
                            <tr>
                                <th scope="row">Email</th>
                                <td>{user?.email}</td>
                            </tr>
                            <tr>
                                <th scope="row">Address</th>
                                <td>{shippingInfo?.address}, {shippingInfo?.city}, {shippingInfo?.postalCode}, {shippingInfo?.country}</td>
                            </tr>
                        </tbody>
                    </table>

                    <h3 className="mt-5 mb-4">Payment Info</h3>
                    <table className="table table-striped table-bordered">
                        <tbody>
                            <tr>
                                <th scope="row">Status</th>
                                <td className={isPaid ? "greenColor" : "redcolor"}>
                                    <b>{paymentInfo?.status || "Not Paid"}</b>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">Method</th>
                                <td>{order?.paymentMethod}</td>
                            </tr>
                            <tr>
                                <th scope="row">Stripe ID</th>
                                <td>{paymentInfo?.id || "Not Paid"}</td>
                            </tr>
                            <tr>
                                <th scope="row">Amount Paid</th>
                                <td>${totalAmount}</td>
                            </tr>
                        </tbody>
                    </table>

                    <h3 className="mt-5 my-4">Order Items:</h3>

                    <hr />
                    <div className="cart-item my-1">
                        {orderItems?.map((item) => (
                            <div className="row my-5" key={item?.product}>
                                <div className="col-4 col-lg-2">
                                    <img src={item?.image || "../images/default_product.png"} alt={item?.name} height="45" width="65" />
                                </div>
                                <div className="col-5 col-lg-5">
                                    <Link to={`/product/${item.product}`}>{item?.name}</Link>
                                    <div className="col-5 col-lg-5">
                                        <ul style={{ listStyleType: "none", padding: 0 }}>
                                            <li>{item.size}</li>
                                            <li>{item.recipecode}</li>
                                            <li>{item.Addnotetocake}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-4 col-lg-2 mt-4 mt-lg-0">
                                    <p>${item?.price}</p>
                                </div>
                                <div className="col-4 col-lg-3 mt-4 mt-lg-0">
                                    <p>{item?.quantity} (Qty)</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <hr />
                </div>

                <div className="col-12 col-lg-3 mt-5">
                    <h4 className="my-4">Status</h4>
                    <div className="mb-3">
                        <select className="form-select" name="status" value={status} onChange={(e) => setStatus(e.target.value)}>
                            <option value="Processing">Processing</option>
                            <option value="Shipped">Shipped</option>
                            <option value="Delivered">Delivered</option>
                        </select>
                    </div>
                    
                    <div className="mb-3">
                        <label className="form-label">Cake Baked By</label>
                        <input
                            type="text"
                            className="form-control"
                            value={cakeBakedBy}
                            onChange={(e) => setCakeBakedBy(e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Cake Baked At</label>
                        <input
                            type="time"
                            className="form-control"
                            value={cakeBakedAt}
                            onChange={(e) => setCakeBakedAt(e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Cake Delivered By</label>
                        <input
                            type="text"
                            className="form-control"
                            value={cakeDeliveredBy}
                            onChange={(e) => setCakeDeliveredBy(e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Cake Delivered At</label>
                        <input
                            type="time"
                            className="form-control"
                            value={cakeDeliveredAt}
                            onChange={(e) => setCakeDeliveredAt(e.target.value)}
                        />
                    </div>

                    <button className="btn btn-primary w-100" onClick={() => updateOrderHandler(order?._id)}>Update Status</button>

                    <h4 className="mt-5 mb-3">Order Invoice</h4>
                    <Link to={`/invoice/order/${order?._id}`} className="btn btn-success w-100">
                        <i className="fa fa-print"></i> Generate Invoice
                    </Link>
                </div>
            </div>
        </AdminLayout>
    );
};

export default ProcessOrder;
