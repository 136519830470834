import React, { useEffect, useState } from "react";
import Loader from "../layout/Loader";
import { toast } from "react-hot-toast";
import { MDBDataTable } from 'mdbreact';
import { Link } from 'react-router-dom';
import '../../App.css'; // Import the global CSS file
import MetaData from "../layout/MetaData";
import { useDeleteOrderMutation, useGetAdminOrdersQuery } from "../../redux/api/orderApi";
import AdminLayout from "../layout/AdminLayout";

const ListOrders = () => {
    const { data, isLoading, error } = useGetAdminOrdersQuery();
    const [deleteOrder, { error: deleteError, isSuccess, isDeleteLoading }] = useDeleteOrderMutation();
    const [orderData, setOrderData] = useState({ columns: [], rows: [] });

    useEffect(() => {
        if (error) {
            toast.error(error?.data?.message);
        }
        if (deleteError) {
            toast.error(deleteError?.data?.message);
        }
        if (isSuccess) {
            toast.success('Order deleted successfully');
        }

        if (data) {
            // Define columns
            const columns = [
                { label: "ID", field: "id", sort: "asc" },
                { label: "Payment Status", field: "paymentStatus", sort: "asc" },
                { label: "Order Status", field: "orderStatus", sort: "asc" },
                { label: "Item Name", field: "itemName", sort: "asc" },
                { label: "Recipe Code", field: "itemRecipCode", sort: "asc" },
                { label: "Size", field: "itemSize", sort: "asc" },
                { label: "Note to Cake", field: "itemAddNote", sort: "asc" },
                { label: "Quantity", field: "itemQuantity", sort: "asc" },
                { label: "Image", field: "itemImage", sort: "asc" },
                { label: "Price", field: "itemPrice", sort: "asc" },
                // Add shipping info columns
                { label: "Delivery Date", field: "deliveryDate", sort: "asc" },
                { label: "Time", field: "time", sort: "asc" },
                { label: "Address", field: "address", sort: "asc" },
                { label: "City", field: "city", sort: "asc" },
                { label: "Phone No", field: "phoneNo", sort: "asc" },
                { label: "Postal Code", field: "postalCode", sort: "asc" },
                { label: "Actions", field: "actions", sort: "asc" }
            ];

            // Transform data into rows
            const rows = data.order?.flatMap((order) =>
                order.orderItems
                    .filter(item => item.name !== "Shipping") // Filter out items with name "Shipping"
                    .map((item) => {
                        // Check if the delivery date has passed
                        const deliveryDate = new Date(order?.shippingInfo?.deliveryDate);
                        const currentDate = new Date();

                        let deliveryDateText = order?.shippingInfo?.deliveryDate;

                        if (deliveryDate < currentDate) {
                            deliveryDateText = `Past Delivery: ${order?.shippingInfo?.deliveryDate}`;
                        }

                        return {
                            id: order?.pid,
                            paymentStatus: order?.paymentInfo.status?.toUpperCase() || "N/A",
                            orderStatus: order?.orderStatus || "N/A",
                            itemName: item.name || "N/A",
                            itemRecipCode: item.recipecode || "N/A",
                            itemSize: item.size || "N/A",
                            itemAddNote: item.Addnotetocake || "",
                            itemQuantity: item.quantity || "N/A",
                            itemImage: item.image ? <img src={item.image} alt={item.name} width="50" /> : "N/A",
                            itemPrice: item.price || "N/A",
                            // Shipping info
                            deliveryDate: deliveryDateText || "N/A",
                            time: order.shippingInfo?.time || "",
                            address: order.shippingInfo?.address || "N/A",
                            city: order.shippingInfo?.city || "N/A",
                            phoneNo: order.shippingInfo?.phoneNo || "N/A",
                            postalCode: order.shippingInfo?.postalCode || "N/A",
                            actions: (
                                <div className="d-flex align-items-center">
                                    <Link to={`/admin/orders/${order?._id}`} className="btn btn-outline-primary me-2">
                                        <i className="fa fa-pencil"></i>
                                    </Link>
                                    <button className="btn btn-outline-danger me-2" onClick={() => deleteProductHandler(order?._id)} disabled={isDeleteLoading}>
                                        <i className="fa fa-trash"></i>
                                    </button>
                                </div>
                            ),
                        };
                    }) || []
            );

            // Sort rows by deliveryDate only
            rows.sort((a, b) => {
                if (a.orderStatus === "Delivered" && b.orderStatus !== "Delivered") {
                    return 1;
                }
                if (a.orderStatus !== "Delivered" && b.orderStatus === "Delivered") {
                    return -1;
                }

                const dateA = new Date(a.deliveryDate.split(" ")[0]); // Only use the date part
                const dateB = new Date(b.deliveryDate.split(" ")[0]); // Only use the date part

                return dateA - dateB; // Sort from nearest to furthest
            });

            setOrderData({ columns, rows });
        }
    }, [data, error, deleteError, isSuccess]);

    const deleteProductHandler = (id) => {
        deleteOrder(id);
    };

    if (isLoading) return <Loader />;

    return (
        <AdminLayout>
            <div className="container">
                <MetaData title={"All Orders"} />
                <h1 className="my-5">{data?.order?.length} Orders</h1>
                <div className="table-responsive">
                    <MDBDataTable
                        data={orderData}  // Pass the data here
                        className="px-3"
                        bordered
                        striped
                        hover
                    />
                </div>
            </div>
        </AdminLayout>
    );
};

export default ListOrders;
