export const PRODUCT_CATEGORIES = [
            "Special", 
            "Snacks", 
            "HighTea", 
            "Wedding", 
            "Birthday", 
            "Workshop",
            "Fruit",
            "Chocolate",
            "cheesecake",
             "Pinatacake" ,
             "Ready to go", 
             "Heartshape cake",
              "Anniversary cake", 
              "Babyshower cake", 
              "birthday cake", "Designercake", 
              "cakes for boys", 
              "cakes for girls", 
              "wedding cakes",
              "bridal Showercake", 
              "baby shower",
               "fondant cakes",
               "Decoration",
               "Bakery Products",
        ];