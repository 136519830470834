import React, { useEffect } from "react";
import './invoice.css';
import MetaData from "../layout/MetaData";
import { useParams } from "react-router-dom";
import { useOrderDetailsQuery } from "../../redux/api/orderApi";
import Loader from "../layout/Loader";
import { toast } from "react-hot-toast";
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';

const Invoice = () => {
    const params = useParams();
    const { data, isLoading, error } = useOrderDetailsQuery(params?.id);
    const order = data?.order || {};
    const { shippingInfo, orderItems, paymentInfo, user, totalAmount } = order;

    useEffect(() => {
        if (error) {
            toast.error(error?.data?.message);
        }
    }, [error]);

    const handleDownload = () => {
        const input = document.getElementById("order_invoice");
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF();
            const pdfWidth = pdf.internal.pageSize.getWidth();
            pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, 0);
            pdf.save(`invoice_${order?.pid}.pdf`);
        });
    }

    if (isLoading || !data) return <Loader />;

    return (
        <div>
            <MetaData title={"Order Invoice"} />
            <div className="order-invoice my-5">
                <div className="row d-flex justify-content-center mb-5">
                    <button className="btn btn-success col-md-5" onClick={handleDownload}>
                        <i className="fa fa-print"></i> Download Invoice
                    </button>
                </div>
                <div id="order_invoice" className="p-3 border border-secondary">
                    <header className="clearfix">
                        <div id="logo">
                            <img src="/images/bb.png" alt="Company Logo" />
                        </div>
                        <h1>INVOICE # {order?.pid}</h1>
                        <div id="company" className="clearfix">
                            <div>Vegetariandelitebakery</div>
                            <div>
                                #101-13140, 80 Ave,
                                <br />
                                Surrey, BC.
                            </div>
                            <div>(604) 502-0104</div>
                            <div>
                                <a href="mailto:info@shopit.com">info@vegetariandelitebakery.com</a>
                            </div>
                        </div>
                        <div id="project">
                            <div><span>Name</span>{user?.name}</div>
                            <div><span>EMAIL</span>{user?.email}</div>
                            <div><span>PHONE</span>{shippingInfo?.phoneNo || 'Not specified'}</div>
                            <div>
                                <span>ADDRESS</span>{shippingInfo?.address}, {shippingInfo?.city}, {shippingInfo?.postalCode}, {shippingInfo?.country}
                            </div>
                            <div><span>DATE :</span>{new Date(order?.createdAt).toLocaleString("en-US")}</div>
                            <div>
                                <span>Delivery Date & Time :</span>
                            </div>
                            <div><span>Delivery On </span>{shippingInfo?.deliveryDate || 'Not specified'}</div>
                            <div><span>Delivery Time</span>{shippingInfo?.time || 'Not specified'}</div>
                            <div><span>Status</span>{paymentInfo?.status || 'Not specified'}</div>
                            <div><span>Method</span>{order?.paymentMethod || 'Not specified'}</div>
                        </div>
                    </header>
                    <main>
                        <table className="mt-5">
                            <thead>
                                <tr>
                                    <th className="service">ID</th>
                                    <th className="desc">NAME</th>
                                    <th>PRICE</th>
                                    <th>QTY</th>
                                    <th>TOTAL</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderItems?.map((item) => (
                                    <tr key={item?.id}>
                                        <td className="service">{item?.product}</td>
                                        <td className="desc">{item?.name}</td>
                                        <td className="unit">${item?.price}</td>
                                        <td className="qty">{item?.quantity}</td>
                                        <td className ="total">${(item?.price * item?.quantity).toFixed(2)}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td colSpan="4">
                                        <b>T AX %</b>
                                    </td>
                                    <td className="total">$0</td>
                                </tr>
                                <tr>
                                    <td colSpan="4" className="grand total">
                                        <b>GRAND TOTAL</b>
                                    </td>
                                    <td className="grand total">${totalAmount.toFixed(2)}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div id="notices">
                            <div>NOTICE:</div>
                            <div className="notice">
                                A finance: No refund balances valid after 30
                                days.
                            </div>
                        </div>
                    </main>
                    <footer>
                        Invoice was created on a computer and is valid without the signature.
                    </footer>
                </div>
            </div>
        </div>
    );
};

export default Invoice;