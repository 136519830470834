import React from "react";
import "../../App.css" // Assuming you are adding custom CSS for further adjustments.

const NotFound = () => {
    return (
        <div className="not-found-container">
            <div className="row justify-content-center text-center">
                <div className="col-12 d-flex justify-content-center align-items-center flex-column page-not-found-wrapper">
                    <img
                        src="/images/404.svg"
                        height="550"
                        width="550"
                        alt="404_not_found"
                        className="not-found-image"
                    />
                    <h5 className="mt-4">
                        Page Not Found. Go to <a href="/">Homepage</a>
                    </h5>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
