import React, { useEffect, useState } from "react";
import Loader from "../layout/Loader";
import { toast } from "react-hot-toast";
import MetaData from "../layout/MetaData";
import AdminLayout from "../layout/AdminLayout";
import { useNavigate, useParams } from "react-router-dom";
import { PRODUCT_CATEGORIES } from "../../constants/constants";
import { useUpdateProductMutation, useGetProductDetailsQuery } from "../../redux/api/productsApi";

const UpdateProduct = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [product, setProduct] = useState({
    name: "",
    price: "",
    sizeSmall: "",
    sizeMedium: "",
    sizeQuarterSlab: "",
    sizeHalfSlab: "",
    sizeFullSlab: "",
    serv1: "",
    serv2: "",
    serv3: "",
    serv4: "",
    flavourChocolate: "",
    flavourBlackForest: "",
    flavourRedVelvet: "",
    flavourFruit: "",
    flavourVanilla: "",
    flavourPineapple: "",
    flavourMango: "",
    flavourStrawberry: "",
    baseRegular: "",
    baseGlutenFree: "",
    baseVeganGlutenFree: "",
    colour: "",
    recipecode: "",
    description: "",
    category: "",
  });

  const [updateProduct, { isLoading, error, isSuccess }] = useUpdateProductMutation();
  const { data } = useGetProductDetailsQuery(params?.id);

  useEffect(() => {
    if (data?.product) {
      setProduct({
        name: data.product.name,
        price: data.product.price,
        sizeSmall: data.product.sizeSmall,
        sizeMedium: data.product.sizeMedium,
        sizeQuarterSlab: data.product.sizeQuarterSlab,
        sizeHalfSlab: data.product.sizeHalfSlab,
        sizeFullSlab: data.product.sizeFullSlab,
        serv1: data.product.serv1,
        serv2: data.product.serv2,
        serv3: data.product.serv3,
        serv4: data.product.serv4,
        flavourChocolate: data.product.flavourChocolate,
        flavourBlackForest: data.product.flavourBlackForest,
        flavourRedVelvet: data.product.flavourRedVelvet,
        flavourFruit: data.product.flavourFruit,
        flavourVanilla: data.product.flavourVanilla,
        flavourPineapple: data.product.flavourPineapple,
        flavourMango: data.product.flavourMango,
        flavourStrawberry: data.product.flavourStrawberry,
        baseRegular: data.product.baseRegular,
        baseGlutenFree: data.product.baseGlutenFree,
        baseVeganGlutenFree: data.product.baseVeganGlutenFree,
        colour: data.product.colour,
        recipecode: data.product.recipecode,
        description: data.product.description,
        category: data.product.category,
      });
    }
    if (error) {
      toast.error(error.data.message);
    }
    if (isSuccess) {
      toast.success("Product updated successfully");
      navigate("/admin/products");
    }
  }, [data, error, isSuccess, navigate]);

  const onChange = (e) => {
    setProduct({ ...product, [e.target.name]: e.target.value });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    updateProduct({ id: params?.id, body: product });
  };

  return (
    <AdminLayout>
      <MetaData title={"Update product"} />
      <div className="row wrapper">
        <div className="col-10 col-lg-10 mt-5 mt-lg-0">
          <form className="shadow rounded bg-body" onSubmit={submitHandler}>
            <h2 className="mb-4">Update Product</h2>

            <div className="mb-3">
              <label htmlFor="name_field" className="form-label">Name</label>
              <input type="text" id="name_field" className="form-control" name="name" value={product.name} onChange={onChange} />
            </div>

            <div className="mb-3">
              <label htmlFor="description_field" className="form-label">Description</label>
              <textarea className="form-control" id="description_field" rows="8" name="description" value={product.description} onChange={onChange}></textarea>
            </div>

            <div className="mb-3">
              <label htmlFor="price_field" className="form-label">Price</label>
              <input type="number" id="price_field" className="form-control" name="price" value={product.price} onChange={onChange} />
            </div>

            <div className="row">
              <div className="mb-3 col">
                <label htmlFor="sizeSmall_field" className="form-label">6" - Round (servings 4-6 ppl)</label>
                <input type="text" id="sizeSmall_field" className="form-control" name="sizeSmall" value={product.sizeSmall} onChange={onChange} />
              </div>
              <div className="mb-3 col">
                <label htmlFor="sizeMedium_field" className="form-label">6" - Tall Round (servings 10-12)</label>
                <input type="text" id="sizeMedium_field" className="form-control" name="sizeMedium" value={product.sizeMedium} onChange={onChange} />
              </div>
              <div className="mb-3 col">
                <label htmlFor="sizeQuarterSlab_field" className="form-label">8" - Round (servings 10-12)</label>
                <input type="text" id="sizeQuarterSlab_field" className="form-control" name="sizeQuarterSlab" value={product.sizeQuarterSlab} onChange={onChange} />
              </div>
              <div className="mb-3 col">
                <label htmlFor="sizeHalfSlab_field" className="form-label">8" - Tall Round (servings 18-20)</label>
                <input type="text" id="sizeHalfSlab_field" className="form-control" name="sizeHalfSlab" value={product.sizeHalfSlab} onChange={onChange} />
              </div>
              <div className="mb-3 col">
                <label htmlFor="sizeFullSlab_field" className="form-label">Quarter - 8"x12" (servings 18-20)</label>
                <input type="text" id="sizeFullSlab_field" className="form-control" name="sizeFullSlab" value={product.sizeFullSlab} onChange={onChange} />
              </div>
            </div>

            <div className="row">
              <div className="mb-3 col">
                <label htmlFor="serv1_field" className="form-label">Half Slab - 12"x15" (servings 45-55)</label>
                <input type="number" id="serv1_field" className="form-control" name="serv1" value={product.serv1} onChange={onChange} min="0" />
              </div>
              <div className="mb-3 col">
                <label htmlFor="serv2_field" className="form-label">Full Slab - 16"x24 (servings 90-100)</label>
                <input type="number" id="serv2_field" className="form-control" name="serv2" value={product.serv2} onChange={onChange} min="0" />
              </div>
              <div className="mb-3 col">
                <label htmlFor="serv3_field" className="form-label">Servings 10-15</label>
                <input type="number" id="serv3_field" className="form-control" name="serv3" value={product.serv3} onChange={onChange} min="0" />
              </div>
              <div className="mb-3 col">
                <label htmlFor="serv4_field" className="form-label">Servings 20-25</label>
                <input type="number" id="serv4_field" className="form-control" name="serv4" value={product.serv4} onChange={onChange} min="0" />
              </div>
            </div>

            <div className="row">
              <div className="mb-3 col">
                <label htmlFor="flavourChocolate_field" className="form-label">Chocolate</label>
                <input type="text" id="flavourChocolate_field" className="form-control" name="flavourChocolate" value={product.flavourChocolate} onChange={onChange} />
              </div>
              <div className="mb-3 col">
                <label htmlFor="flavourBlackForest_field" className="form-label">Black Forest</label>
                <input type="text" id="flavourBlackForest_field" className="form-control" name="flavourBlackForest" value={product.flavourBlackForest} onChange={onChange} />
              </div>
              <div className="mb-3 col">
                <label htmlFor="flavourRedVelvet_field" className="form-label">Red Velvet</label>
                <input type="text" id="flavourRedVelvet_field" className="form-control" name="flavourRedVelvet" value={product.flavourRedVelvet} onChange={onChange} />
              </div>
              <div className="mb-3 col">
                <label htmlFor="flavourFruit_field" className="form-label">Fruit</label>
                <input type="text" id="flavourFruit_field" className="form-control" name="flavourFruit" value={product.flavourFruit} onChange={onChange} />
              </div>
            </div>

            <div className="row">
              <div className="mb-3 col">
                <label htmlFor="flavourVanilla_field" className="form-label">Vanilla</label>
                <input type="text" id="flavourVanilla_field" className="form-control" name="flavourVanilla" value={product.flavourVanilla} onChange={onChange} />
              </div>
              <div className="mb-3 col">
                <label htmlFor="flavourPineapple_field" className="form-label">Pineapple</label>
                <input type="text" id="flavourPineapple_field" className="form-control" name="flavourPineapple" value={product.flavourPineapple} onChange={onChange} />
              </div>
              <div className="mb-3 col">
                <label htmlFor="flavourMango_field" className="form-label">Mango</label>
                <input type="text" id="flavourMango_field" className="form-control" name="flavourMango" value={product.flavourMango} onChange={onChange} />
              </div>
              <div className="mb-3 col">
                <label htmlFor="flavourStrawberry_field" className="form-label">Strawberry</label>
                <input type="text" id="flavourStrawberry_field" className="form-control" name="flavourStrawberry" value={product.flavourStrawberry} onChange={onChange} />
              </div>
            </div>

            <div className="row">
              <div className="mb-3 col">
                <label htmlFor="baseRegular_field" className="form-label">Regular Base</label>
                <input type="text" id="baseRegular_field" className="form-control" name="baseRegular" value={product.baseRegular} onChange={onChange} />
              </div>
              <div className="mb-3 col">
                <label htmlFor="baseGlutenFree_field" className="form-label">Gluten-Free Base</label>
                <input type="text" id="baseGlutenFree_field" className="form-control" name="baseGlutenFree" value={product.baseGlutenFree} onChange={onChange} />
              </div>
              <div className="mb-3 col">
                <label htmlFor="baseVeganGlutenFree_field" className="form-label">Vegan Gluten-Free Base</label>
                <input type="text" id="baseVeganGlutenFree_field" className="form-control" name="baseVeganGlutenFree" value={product.baseVeganGlutenFree} onChange={onChange} />
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="colour_field" className="form-label">Colour</label>
              <input type="text" id="colour_field" className="form-control" name="colour" value={product.colour} onChange={onChange} />
            </div>

            <div className="mb-3">
              <label htmlFor="recipecode_field" className="form-label">Product Code</label>
              <input type="text" id="recipecode_field" className="form-control" name="recipecode" value={product.recipecode} onChange={onChange} />
            </div>

            <div className="mb-3">
              <label htmlFor="category_field" className="form-label">Category</label>
              <select id="category_field" className="form-control" name="category" value={product.category} onChange={onChange}>
                <option value="">Choose Category</option>
                {PRODUCT_CATEGORIES.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>

            <button id="update_button" type="submit" className="btn btn-primary py-2 w-100" disabled={isLoading}>
              {isLoading ? <Loader /> : "UPDATE"}
            </button>
          </form>
        </div>
      </div>
    </AdminLayout>
  );
};

export default UpdateProduct;