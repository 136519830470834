import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import UserLayout from '../layout/UserLayout';
import { useUpdatePasswordMutation } from '../../redux/api/userApi';
import MetaData from "../layout/MetaData";

const UpdatePassword = () => {
    const [oldPassword, setOldPassword] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();
    const [updatePassword, { isLoading, error, isSuccess }] = useUpdatePasswordMutation();
   
    useEffect(() => {
        if (error) {
            toast.error(error?.data?.message || "An error occurred");
        }
        if (isSuccess) {
            toast.success("Password updated successfully");
            navigate("/me/profile");
        }
    }, [error, isSuccess, navigate]);

    const submitHandler = (e) => {
        e.preventDefault();

        if (!oldPassword || !password) {
            toast.error("Please fill in both fields");
            return;
        }

        const userData = {
            oldPassword,
            password,
        };
        updatePassword(userData);
    };

    return (
        <UserLayout>
            <MetaData title="Update Password" />
            <div className="row wrapper">
                <div className="col-10 col-lg-8">
                    <form className="shadow rounded bg-body" onSubmit={submitHandler}>
                        <h2 className="mb-4">Update Password</h2>
                        <div className="mb-3">
                            <label htmlFor="old_password_field" className="form-label">
                                Old Password
                            </label>
                            <input
                                type="password"
                                id="old_password_field"
                                className="form-control"
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="new_password_field" className="form-label">
                                New Password
                            </label>
                            <input
                                type="password"
                                id="new_password_field"
                                className="form-control"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <button 
                            type="submit" 
                            className="btn update-btn w-100" 
                            disabled={isLoading}
                        >
                            {isLoading ? "Updating..." : "Update Password"}
                        </button>
                    </form>
                </div>
            </div>
        </UserLayout>
    );
};

export default UpdatePassword;
