import React, { useEffect, useState } from 'react';
import MetaData from "../layout/MetaData";
import { useSelector } from 'react-redux';
import CheckoutSteps from './CheckoutSteps';
import { calculateOrderCost } from '../../helpers/helpers';
import { useCreateNewOrderMutation, useStripeCheckoutSessionMutation } from '../../redux/api/orderApi';
import { toast } from "react-hot-toast";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { clearCart } from "../../redux/features/cartSlice";

const PaymentMethod = () => {
  const [method, setMethod] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { shippingInfo, cartItems } = useSelector((state) => state.cart);
  const [createNewOrder, { error, isSuccess }] = useCreateNewOrderMutation();
  const [stripeCheckoutSession, { data: checkoutData, error: checkoutError, isLoading }] = useStripeCheckoutSessionMutation();

  useEffect(() => {
    if (checkoutData) {
      window.location.href = checkoutData?.url;
    }
    if (checkoutError) {
      toast.error(checkoutError.message);
    }
  }, [checkoutData, checkoutError]);

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
    }
    if (isSuccess) {
      navigate("/home?order_success=true");
      dispatch(clearCart()); // Clear the cart on successful order
      toast.success("Order created successfully!");
    }
  }, [error, isSuccess, navigate]);

  const submitHandler = (e) => {
    e.preventDefault();

    const { itemsPrice, shippingPrice, deliveryCost, totalPrice, tax, quantity } = calculateOrderCost(cartItems, shippingInfo);

    if (method === "COD" || method === "Card") {
      const orderData = {
        shippingInfo: shippingInfo,
        orderItems: cartItems,
        itemsPrice,
        shippingAmount: method === "COD" ? shippingPrice : deliveryCost,
        totalAmount: method === "COD" ? itemsPrice : totalPrice,
        taxAmount: tax,
        quantity: quantity,
      };
      stripeCheckoutSession(orderData);
    } else {
      toast.error("Please select a payment method.");
    }
  };

  // Define pick-up locations
  const pickUpLocations = [
    "Abbotsford -2610 Cedar Park Pl, BC V2T 3S5.",
    "Surrey - 8028 128 St Unit 104, BC V3W 4E9"
  ];

  // Determine if the selected city is a pick-up location
  const isPickUpLocation = pickUpLocations.some(location => location.startsWith(shippingInfo?.city));

  return (
    <>
      <MetaData title={"Payment Method"} />
      <CheckoutSteps shipping ConfirmOrder payment />
      <div className="row wrapper">
        <div className="col-10 col-lg-5">
          <form className="shadow rounded bg-body" onSubmit={submitHandler}>
            <h2 className="mb-4">Select Payment Method</h2>

            {/* Conditional rendering based on the selected city */}
            {isPickUpLocation ? (
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="payment_mode"
                  id="codradio"
                  value="COD"
                  onChange={(e) => setMethod("COD")}
                />
                <label className="form-check-label" htmlFor="codradio">
                  Pay And Pick Up From Store
                </label>
              </div>
            ) : (
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="payment_mode"
                  id="cardradio"
                  value="Card"
                  onChange={(e) => setMethod("Card")}
                />
                <label className="form-check-label" htmlFor="cardradio">
                  Get Delivered To You
                </label>
              </div>
            )}

            <button id="shipping_btn" type="submit" className="btn py-2 w-100" disabled={isLoading}>
              {isLoading ? "Processing..." : "CONTINUE"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default PaymentMethod;
