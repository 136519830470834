import React, { useEffect, useState } from "react";
import Loader from "../layout/Loader";
import { toast } from "react-hot-toast";
import { MDBDataTable } from 'mdbreact';
import { Link } from 'react-router-dom';
import '../../App.css'; // Import the global CSS file
import MetaData from "../layout/MetaData";
import AdminLayout from "../layout/AdminLayout";
import { useDeleteUserMutation, useGetAdminUsersQuery } from "../../redux/api/userApi";

const ListUsers = () => {
    const { data, isLoading, error } = useGetAdminUsersQuery();
    const [users, setUsers] = useState({ columns: [], rows: [] });

    const [deleteUser, { error: deleteError, isLoading: isDeleteLoading, isSuccess }] = useDeleteUserMutation();

    const deleteUserHandler = (id) => {
        deleteUser(id);
    };

    useEffect(() => {
        if (error) {
            toast.error(error?.data?.message);
        }
        if (deleteError) {
            toast.error(deleteError?.data?.message);
        }
        if (isSuccess) {
            toast.success('User deleted successfully');
        }

        if (data) {
            const columns = [
                { label: "ID", field: "id", sort: "asc" },
                { label: "Name", field: "name", sort: "asc" },
                { label: "Email", field: "email", sort: "asc" },
                { label: "Role", field: "role", sort: "asc" },
                { label: "Actions", field: "actions", sort: "asc" },
            ];

            const rows = data.users?.map((user) => ({
                id: user?._id,
                name: user?.name,
                email: user?.email,
                role: user?.role,
                actions: (
                    <div className="d-flex align-items-center">
                        <Link to={`/admin/users/${user?._id}`} className="btn btn-outline-primary me-2">
                            <i className="fa fa-pencil"></i>
                        </Link>
                       
                        <button
                            className="btn btn-outline-danger me-2"
                            onClick={() => deleteUserHandler(user?._id)}
                            disabled={isDeleteLoading}
                        >
                            <i className="fa fa-trash"></i>
                        </button>
                    </div>
                ),
            }));
            setUsers({ columns, rows });
        }
    }, [data, error, deleteError, isSuccess, isDeleteLoading]);

    if (isLoading) return <Loader />;

    return (
        <AdminLayout>
            <div className="container">
                <MetaData title={"All Users"} />
                <h1 className="my-5">{data?.users?.length} Users</h1>
                <div className="table-responsive">
                    <MDBDataTable
                        data={users}
                        className="px-3"
                        bordered
                        striped
                        hover
                    />
                </div>
            </div>
        </AdminLayout>
    );
};

export default ListUsers;
