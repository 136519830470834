import React, { useEffect, useState } from "react";
import Loader from "../layout/Loader";
import { toast } from "react-hot-toast";
import {  useNavigate, useParams } from "react-router-dom";
import '../../App.css'; // Import the global CSS file
import MetaData from "../layout/MetaData";
import AdminLayout from "../layout/AdminLayout";
import { useGetUserDetailsQuery, useUpdateUserMutation } from '../../redux/api/userApi';

const UpdateUser = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [role, setRole] = useState("");
 
    const navigate = useNavigate();
    const params = useParams();
    
    // Fetch the user details using the ID from params
    const { data, isLoading } = useGetUserDetailsQuery(params.id);
    const [updateUser, { error, isSuccess }] = useUpdateUserMutation();
  
    useEffect(() => {
        if (data?.user) {
            setName(data.user.name);
            setEmail(data.user.email);
            setRole(data.user.role);
        }
        if (error) {
            toast.error(error?.data?.message);
        }
        if (isSuccess) {
            toast.success("User updated");
            navigate("/admin/users");
        }
    }, [data, error, isSuccess, navigate]);

    const submitHandler = (e) => {
        e.preventDefault();
        const userData = {
            name,
            email,
            role,
        };
        updateUser({ id: params.id, body: userData });
    };

    if (isLoading) return <Loader />;

    return (
        <AdminLayout>
            <MetaData title="Update User" />
            <div className="row wrapper">
                <div className="col-10 col-lg-8">
                    <form className="shadow-lg" onSubmit={submitHandler}>
                        <h2 className="mb-4">Update User</h2>
  
                        <div className="mb-3">
                            <label htmlFor="name_field" className="form-label">Name</label>
                            <input
                                type="text"
                                id="name_field"
                                className="form-control"
                                name="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
  
                        <div className="mb-3">
                            <label htmlFor="email_field" className="form-label">Email</label>
                            <input
                                type="email"
                                id="email_field"
                                className="form-control"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="role_field" className="form-label">Role</label>
                            <select
                                id="role_field"
                                className="form-select"
                                name="role"
                                value={role}
                                onChange={(e) => setRole(e.target.value)}
                            >
                                <option value="user">User</option>
                                <option value="admin">Admin</option>
                            </select>
                        </div>
  
                        <button type="submit" className="btn update-btn w-100 py-2">
                            Update
                        </button>
                    </form>
                </div>
            </div>
        </AdminLayout>
    );
};

export default UpdateUser;
