import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getPriceQueryParams } from "../../helpers/helpers";
import { PRODUCT_CATEGORIES } from "../../constants/constants";
import StarRatings from "react-star-ratings";
import "../../App.css"
const Filters = () => {

    const [min, setMin] = useState()
    const [max, setMax] = useState()

    const navigate = useNavigate();
    let [searchParams] = useSearchParams();


    useEffect(() =>{
    searchParams.has('min') && setMin(searchParams.get('min') )
  }, [])

   // Handle Category & Rating
const handleClick = (checkbox) => {
    const checkboxes = document.getElementsByName(checkbox.name);
  
    // Uncheck all other checkboxes in the same group
    checkboxes.forEach((item) => {
      if (item !== checkbox) item.checked = false;
    });
  
    // Update the search parameters based on the checkbox state
    if (!checkbox.checked) {
      // Remove filter from query if unchecked
      if (searchParams.has(checkbox.name)) {
        searchParams.delete(checkbox.name);
      }
    } else {
      // Set or update the filter value
      if (searchParams.has(checkbox.name)) {
        searchParams.set(checkbox.name, checkbox.value);
      } else {
        // Append new filter
        searchParams.append(checkbox.name, checkbox.value);
      }
    }
  
    // Update the URL with the new search parameters
    const newPath = window.location.pathname + "?" + searchParams.toString();
    window.history.pushState(null, "", newPath);
  };
  
     // Handle price filter
     const handleButtonClick = (e) => {
        e.preventDefault();

        searchParams = getPriceQueryParams(searchParams, "min", min )
        searchParams = getPriceQueryParams(searchParams, "max", max )
        const path = window.location.pathname + "?" + searchParams.toString();
  
        navigate(path);
    }

    const defaultCheckHandler = (checkboxType, checkboxValue) =>{
        const value = searchParams.get(checkboxType)
        if(checkboxValue === value) return true;
        return false;
    }

    return (
      <div className="border p-3 filter" style={{ border: "1px solid black !important" }}>


        <h3>Filters</h3>
        <hr />
        <h5 className="filter-heading mb-3">Price</h5>
        <form
          id="filter_form"
          className="px-2"
          onSubmit={handleButtonClick}
        >
          <div className="row">
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Min ($)"
                name="min"
                value={min}
                onChange={(e) => setMin(e.target.value)}
              />
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Max ($)"
                name="max"
                value={max}
                onChange={(e) => setMax(e.target.value)}
              />
            </div>
            <div className="col">
              <button type="submit" className="btn btn-primary">GO</button>
            </div>
          </div>
        </form>
        <hr />
        <h5 className="mb-3">Category</h5>
   {PRODUCT_CATEGORIES?.map((category) =>(
    <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            name="category"
            id="check4"
            value={category}
            defaultChecked = {defaultCheckHandler("category", category)}
            onClick={(e) => handleClick(e.target)}
          />
          <label className="form-check-label" for="check4"> {""} {category} </label>
        </div>
   ))}

       
  
        <hr />
        <h5 className="mb-3">Ratings</h5>
    {[5,4,3,2,1].map((rating) =>(
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            name="ratings"
            id="check7"
            value={rating}
            defaultChecked = {defaultCheckHandler("ratings", rating?.toString())}
            onClick={(e) => handleClick(e.target)}
            
          />
          <label className="form-check-label" for="check7">
          <StarRatings
                   rating={rating}
                   starRatedColor="#ffb829"
                   //changeRating={this.changeRating}
                   numberOfStars={5}
                   name="rating"
                   starDimension="21px"
                   starSpacing="1px"
                   />
          </label>
        </div>
    ))}
      </div>
    );
};
export default Filters