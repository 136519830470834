import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const orderApi = createApi({
  reducerPath: 'orderApi',
  baseQuery: fetchBaseQuery({ baseUrl: "/api/" }),
  tagTypes: ["Order", "AdminOrders"], // Including both tags for clarity
  endpoints: (builder) => ({
    Formorder: builder.mutation({
      query: (body) => ({
        url: "/ordercake",
        method: "POST",
        body, // Corrected here
      }),
    }),
    createNewOrder: builder.mutation({
      query: (body) => ({
        url: "/orders/new",
        method: "POST",
        body,
      }),
    }),
    myOrders: builder.query({
      query: () => `/me/orders`,
      providesTags: ['Order'],
    }),
    orderDetails: builder.query({
      query: (id) => `/orders/${id}`,
      providesTags: ['Order'],
    }),
    getDashboardSales: builder.query({
      query: ({ startDate, endDate }) => `/admin/get_sales/?startDate=${startDate}&endDate=${endDate}`,
    }),
    stripeCheckoutSession: builder.mutation({
      query: (body) => ({
        url: "/payment/checkout_session",
        method: "POST",
        body,
      }),
    }),
    getAdminOrders: builder.query({
      query: () => `/admin/orders`,
      providesTags: ['AdminOrders'], // Make sure this tag is consistent
    }),
    updateOrder: builder.mutation({
      query: ({ id, body }) => ({
        url: `/admin/orders/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ['Order'], // Correctly invalidating the 'Order' tag
    }),
    deleteOrder: builder.mutation({
      query: (id) => ({
        url: `/admin/orders/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ['AdminOrders'], // Correctly invalidating the 'AdminOrders' tag
    }),
    checkOrderCount: builder.query({
      query: (deliveryDate) => `/orders/ordercount/${deliveryDate}`,
      providesTags: ['Order'], // Provides cache for the "Order" tag
      invalidatesTags: ['Order'], // Invalidate the "Order" cache when this query is executed
    }),
    
  }),
});

export const {
  useCreateNewOrderMutation,
  useStripeCheckoutSessionMutation,
  useMyOrdersQuery,
  useOrderDetailsQuery,
  useLazyGetDashboardSalesQuery,
  useGetAdminOrdersQuery,
  useUpdateOrderMutation,
  useDeleteOrderMutation,
  useCheckOrderCountQuery,
  useFormorderMutation,
} = orderApi;
