import React from 'react';
import "../test/footer.css";

const Footer = () => {
  return (
    <footer>
      <div className="footer-div main-footer-div">
        <img id="logo" src="../images/c.png" alt="Delight" />
        <div className="icon-collections">
          {/* Facebook */}
          <div className="icon">
            <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/veggiebakerysurrey/">
              <i className="fa-brands fa-facebook-f brands" />
            </a>
          </div>
          {/* Instagram */}
          <div className="icon">
            <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/veggiebakerysurrey/?hl=en">
              <i className="fa-brands fa-instagram brands" />
            </a>
          </div>
          {/* Mail */}
          <div className="icon">
            <a target="_blank" rel="noopener noreferrer" href="mailto:contactus@veggiecakes.ca">
              <i className="fa-regular fa-envelope brands" />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-div">
        <li className="main">Quick Links</li>
        <li><a href="/home">Home</a></li>
        <li><a href="/ourcakes">OurCakes</a></li>
        <li><a href="/aboutus">About Us</a></li>
      </div>
      <div className="footer-div">
        <li className="main">For Customer</li>
        <li><a href="/faq">For Frequently Asked</a></li>
        <li><a href="/delivery">Delivery & Pick Up Service</a></li>
      </div>
      <div className="footer-div">
        <li className="main">Our Locations</li>
        <li>2610 Cedar Park Pl, Abbotsford, BC V2T 3S5.</li>
        <li>8078 128 St Unit 103, Surrey, BC V3W 4E9</li>
        <li><a href="mailto:info@vegetariandelitebakery.com">info@vegetariandelitebakery.com</a></li>
        <li><i className="fa-solid fa-phone phone" /> (604)-502-0104</li>
      </div>
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        © 2024 Vegetarian Delite Bakery.
      </div>
    </footer>
  );
};

export default Footer;
