import React, { useState } from "react";
import "./style.css";

const FAQSection = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="faq-item">
      <h2 className="faq-question" onClick={() => setIsOpen(!isOpen)}>
        {question}
      </h2>
      {isOpen && <p className="faq-answer">{answer}</p>}
    </div>
  );
};

const ForallQuestion = () => {
  return (
    <div className="faq-page">
      <header className="faq-header">
        <h1>Frequently Asked Questions</h1>
      </header>

      <section className="faq-content">
  <h2 className="faq-divider">Order Details</h2>
  <FAQSection
    question="What is the recommended lead time for cake orders?"
    answer="We advise placing your cake order as soon as your event date is set. To ensure availability, it is best to order several days in advance, although we strive to accommodate last-minute requests when possible."
  />
  <FAQSection
    question="Is pre-payment necessary to confirm an order?"
    answer="Pre-payment is required to confirm your cake order. We accept debit, and credit card payments. Cheques are not accepted."
  />
  <FAQSection
    question="Can I cancel my order and receive a refund?"
    answer="Orders cannot be canceled or refunded once placed. As each cake is custom-made, it cannot be resold."
  />
  <FAQSection
    question="Can I place an order in person or do I need to schedule an appointment?"
    answer={
      <>
      All orders are taken online only. If you may need to place orders in person, please contact us by <a href="tel:+16045020104">phone</a>.
    </>
    }
    
  />

  <h2 className="faq-divider">Ingredient Information</h2>
  <FAQSection
    question="Do your cakes contain eggs?"
    answer="Our cakes are free from eggs, animal fats, and nuts. We maintain a completely egg-free, animal fat-free environment."
  />
  <FAQSection
    question="Are nuts used in any of your products?"
    answer="Our bakery products are processed in a facitlity that handles soys, sulphites,tree nuts and diary. "
  />
  <FAQSection
    question="Can I order a dairy-free cake?"
    answer="Yes, dairy-free cakes can be ordered upon request. The selection of dairy-free flavors is limited. We also frequently offer dairy-free cupcakes, vegetable patties, and cream horns."
  />
  <FAQSection
    question="Do you provide gluten-free cakes?"
    answer="Yes, gluten-free cakes are available by request. Any cake can be made gluten-free. Please select the gluten-free option when placing your order online. Please note That gluten free cakes are made and stored in same factilty, there are chances of cross-contmination"
  />

  <h2 className="faq-divider">Payment Information</h2>
  <FAQSection
    question="What forms of payment are accepted?"
    answer="We accept debit, and credit card payments. We are unable to accept cheques."
  />
  <FAQSection
    question="Are there specific requirements for large orders?"
    answer="For all orders, we require two govemrment IDs with name and picture (driver’s license, etc), Orders may be canceled if payment issues occur."
  />
</section>


      
    </div>
  );
};

export default ForallQuestion;
