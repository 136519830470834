import React, { useEffect, useState } from "react";
import Loader from "../layout/Loader";
import { toast } from "react-hot-toast";
import { MDBDataTable } from 'mdbreact';
import { Link } from 'react-router-dom';
import '../../App.css'; // Import the global CSS file
import MetaData from "../layout/MetaData";
import { useGetAdminProductsQuery, useDeleteProductMutation } from "../../redux/api/productsApi";
import AdminLayout from "../layout/AdminLayout";

const ListProducts = () => {
    const { data, isLoading, error } = useGetAdminProductsQuery();

    const [deleteProduct, { isLoading: isDeleteLoading, error: deleteError, isSuccess }] = useDeleteProductMutation();

    const deleteProductHandler = (id) => {
        deleteProduct(id);
    };

    const [products, setProducts] = useState({ columns: [], rows: [] });

    useEffect(() => {
        if (error) {
            toast.error(error?.data?.message);
        }

        if (deleteError) {
            toast.error(deleteError?.data?.message);
        }

        if (isSuccess) {
            toast.success('Product deleted successfully');
        }

        if (data) {
            const columns = [
                { label: "ID", field: "id", sort: "asc" },
                { label: "Name", field: "name", sort: "asc" },
                { label: "Price", field: "price", sort: "asc" },
                { label: "Size", field: "size", sort: "asc" },
                { label: "Flvour", field: "flvour", sort: "asc" }, // No change here
                { label: "Colour", field: "colour", sort: "asc" },
                { label: "Recipe Code", field: "recipecode", sort: "asc" },
                { label: "Category", field: "category", sort: "asc" },
                { label: "Actions", field: "actions", sort: "asc" },
            ];

            const rows = data.products.map((product) => ({
                id: product._id,
                name: product.name,
                price: product.price,
                size: product.size,
                flvour: product.flvour, // No change here
                colour: product.colour,
                recipecode: product.recipecode,
                category: product.category,
                actions: (
                    <div className="d-flex align-items-center">
                        <Link to={`/admin/products/${product._id}`} className="btn btn-outline-primary me-2">
                            <i className="fa fa-pencil"></i>
                        </Link>
                        <Link to={`/admin/products/${product._id}/upload_images`} className="btn btn-outline-success me-2">
                            <i className="fa fa-image"></i>
                        </Link>
                        <button className="btn btn-outline-danger me-2" 
                            onClick={() => deleteProductHandler(product?._id)}
                            disabled={isDeleteLoading}>
                            <i className="fa fa-trash"></i>
                        </button>
                    </div>
                ),
            }));
            setProducts({ columns, rows });
        }
    }, [data, error, deleteError, isSuccess]);

    if (isLoading) return <Loader />;

    return (
        <AdminLayout>
            <div className="container"> 
                <MetaData title={"All Products"} />
                <h1 className="my-5">{data?.products?.length} Products</h1>
                <div className="table-responsive">
                    <MDBDataTable
                        data={products}
                        className="px-3"
                        bordered
                        striped
                        hover
                    />
                </div>
            </div>
        </AdminLayout>
    );
};

export default ListProducts;
