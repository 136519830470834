import React from "react";
import "../test/style.css"

const AboutUs = () => {
  const containerStyle = {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '20px'
  };

  const sectionStyle = {
    textAlign: 'center',
    marginBottom: '40px'
  };

  const imageContainerStyle = {
    position: 'relative',
    width: '100%',
    maxWidth: '300px',
    margin: '10px',
    display: 'inline-block'
  };

  const imgStyle = {
    width: '100%',
    height: 'auto'
  };

  const imgOverlayStyle = {
    position: 'absolute',
    bottom: '0',
    left: '0',
    right: '0',
    background: 'rgba(0, 0, 0, 0.5)',
    color: '#fff',
    textAlign: 'center',
    padding: '10px'
  };

  return (
    <div style={{ fontFamily: 'Arial, sans-serif' }}>
      <div style={containerStyle}>
       
<br/>

        {/* About Summary Section */}
        <section id="home" style={sectionStyle}>
  <div>
    <h1>Welcome to Delite Bakery</h1>
    <p>
      Since 1994, Delite Bakery has been a trusted name in offering premium, 100% eggless, non-GMO, and animal-fat-free baked goods. Serving the Lower Mainland through our locations in Surrey and Abbotsford, we take pride in delivering excellence with every bite.
    </p>
    <p>
      At Vegetarian Delite Bakery, we are committed to fostering a healthier lifestyle. All of our products are crafted without the use of eggs, animal fats, or genetically modified ingredients, ensuring our offerings align with modern health standards.
    </p>
    <p>
      Indulge in your favorite treats without compromise. From cookies and cakes to muffins, pastries and more, we provide an extensive range of wholesome, sugar-free, vegan, and gluten-free options that cater to diverse dietary needs.
    </p>
    <p>
      Our state-of-the-art facility adheres to stringent Good Manufacturing Practices (GMP), ensuring the highest quality and safety in every product. Our dedicated team proudly serves the community through our showrooms in Surrey and Abbotsford.
    </p>
    <p>
      You can also find our products in leading supermarkets across Canada and the USA, including Save-On-Foods, Fruiticana, Sabzi Mandi, Sun Farm, and more.
    </p>
  </div>
</section>
        {/* About Images Section */}
        <section id="about-images" style={{ ...sectionStyle, display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
  <div style={imageContainerStyle}>
    <img 
      src="../images/shop.jpg" 
      alt="Bakery Interior" 
      style={{ width: '300px', height: '300px', objectFit: 'cover' }} 
    />
    <div style={imgOverlayStyle}>
      <p>Our Cozy Bakery</p>
    </div>
  </div>
  <div style={imageContainerStyle}>
    <img 
      src="../images/H.jpg" 
      alt="Freshly Baked Goods" 
      style={{ width: '300px', height: '300px', objectFit: 'cover' }} 
    />
    <div style={imgOverlayStyle}>
      <p>Freshly Baked Goods</p>
    </div>
  </div>
  <div style={imageContainerStyle}>
    <img 
      src="../images/aa.png" 
      alt="Friendly Staff" 
      style={{ width: '300px', height: '300px', objectFit: 'cover' }} 
    />
    <div style={imgOverlayStyle}>
      <p>Visit Us Too See More</p>

    </div>
  </div>
</section>


        {/* Opening Hours Section */}
        <section id="opening-hours" style={sectionStyle}>
          <div>
            <h2>Opening Hours</h2>
            <ul style={{ listStyleType: 'none', padding: '0' }}>
              <li>Monday - Sunday: 8:30 AM - 9:30 PM</li>
              <li>We Are Open Throughout Year On Public Holidays</li>
              <li> </li>
            </ul>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AboutUs;
