import React from 'react';
import "./style.css"
const DeliveryPickupService = () => {
  return (
    <div className="service-container">
       <section className="service-section">
        <div className="service-content">
          <img 
            src="../images/shop.jpg" 
            alt="Pickup Service" 
            className="service-image" 
          />
          <div className="service-text">
          <h2>Pickup Service</h2>

<p>We have two store Pick up loations Surrey and Abbotsford, Place your order online and choose the Pickup option. Select a date and time that fits your schedule.</p>
<p> We open both stores at 10:00 am until 8.30 pm </p>
<p>Please collect your order at least 30 minutes before closing. We do not provide refund for orders not picked up before closing.</p>
          </div>
        </div>
      </section>

      <section className="service-section">
  <div className="service-content">
    <div className="service-text">
      <h2>Delivery Service</h2>
      <p>We provide delivery services across Vancouver City and nearby areas. Delivery charges are listed below.</p>
      <p>Delivery is available from Monday to Sunday, 10:00 am to 7:00 pm. Due to traffic conditions, specific delivery times cannot be guaranteed. For timely deliveries, consider scheduling for one day in advance. Cakes remain fresh for 4-5 days when refrigerated.</p>
      <p>Our reliable courier service ensures careful handling of your delivery.</p>
      <p>Ensure the recipient is available to accept the delivery. We are not responsible for unattended deliveries. For surprise gifts, confirm the recipient's availability.</p>
    </div>
    <img 
      src="../images/Van3.png" 
      alt="Delivery Service" 
      className="service-image" 
    />
  </div>
</section>


      <section className="delivery-charges">
        <h2>Delivery Charges Overview</h2>
        
        <table className="delivery-table">
          <thead>
            <tr>
              <th>Location</th>
              <th>Mon - Fri</th>
              <th>Mon - Fri (Same Day)</th>
              <th>Sat - Sun & Holidays</th>
              <th>Sat - Sun & Holidays (Same Day)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Surrey, British Columbia</td>
              <td>$15</td>
              <td>$20</td>
              <td>$25</td>
              <td>$30</td>
            </tr>
            <tr>
              <td>Burnaby, British Columbia</td>
              <td>$25</td>
              <td>$30</td>
              <td>$35</td>
              <td>$40</td>
            </tr>
            <tr>
              <td>Maple Ridge, British Columbia</td>
              <td>$25</td>
              <td>$30</td>
              <td>$35</td>
              <td>$40</td>
            </tr>
            <tr>
              <td>Port Coquitlam</td>
              <td>$30</td>
              <td>$35</td>
              <td>$40</td>
              <td>$45</td>
            </tr>
            <tr>
              <td>Richmond, British Columbia</td>
              <td>$30</td>
              <td>$35</td>
              <td>$40</td>
              <td>$45</td>
            </tr>
            <tr>
              <td>White Rock, British Columbia</td>
              <td>$25</td>
              <td>$30</td>
              <td>$35</td>
              <td>$40</td>
            </tr>
            <tr>
              <td>Langley, British Columbia</td>
              <td>$25</td>
              <td>$30</td>
              <td>$35</td>
              <td>$40</td>
            </tr>
            <tr>
              <td>Walnut Grove</td>
              <td>$30</td>
              <td>$35</td>
              <td>$40</td>
              <td>$45</td>
            </tr>
            <tr>
              <td>Coquitlam</td>
              <td>$30</td>
              <td>$35</td>
              <td>$40</td>
              <td>$45</td>
            </tr>
            <tr>
              <td>Delta, British Columbia</td>
              <td>$25</td>
              <td>$30</td>
              <td>$35</td>
              <td>$40</td>
            </tr>
            <tr>
              <td>New Westminster</td>
              <td>$25</td>
              <td>$30</td>
              <td>$35</td>
              <td>$40</td>
            </tr>
            <tr>
              <td>Port Moody</td>
              <td>$30</td>
              <td>$35</td>
              <td>$40</td>
              <td>$45</td>
            </tr>
            <tr>
              <td>Vancouver</td>
              <td>$40</td>
              <td>$45</td>
              <td>$50</td>
              <td>$55</td>
            </tr>
            <tr>
              <td>Ladner, British Columbia</td>
              <td>$30</td>
              <td>$35</td>
              <td>$40</td>
              <td>$45</td>
            </tr>
            <tr>
              <td>West Vancouver</td>
              <td>$40</td>
              <td>$45</td>
              <td>$50</td>
              <td>$55</td>
            </tr>
            <tr>
              <td>East Vancouver</td>
              <td>$40</td>
              <td>$45</td>
              <td>$50</td>
              <td>$55</td>
            </tr>
            <tr>
              <td>North Vancouver</td>
              <td>$40</td>
              <td>$45</td>
              <td>$50</td>
              <td>$55</td>
            </tr>
            <tr>
              <td>Abbotsford, British Columbia</td>
              <td>$15</td>
              <td>$20</td>
              <td>$25</td>
              <td>$30</td>
            </tr>
            <tr>
              <td>Downtown Vancouver</td>
              <td>$40</td>
              <td>$45</td>
              <td>$50</td>
              <td>$55</td>
            </tr>
            <tr>
              <td>Pitt Meadows</td>
              <td>$35</td>
              <td>$40</td>
              <td>$45</td>
              <td>$50</td>
            </tr>
            <tr>
              <td>Mission, British Columbia</td>
              <td>$40</td>
              <td>$45</td>
              <td>$50</td>
              <td>$55</td>
            </tr>
            <tr>
              <td>Aldergrove, County Antrim</td>
              <td>$25</td>
              <td>$30</td>
              <td>$35</td>
              <td>$40</td>
            </tr>
          </tbody>
        </table>
        <p>Don’t see your location? Reach out to us for more information.</p>
      </section>
    </div>
  );
};

export default DeliveryPickupService;
