import React, { useEffect } from "react";
import { useMyOrdersQuery } from "../../redux/api/orderApi";
import Loader from "../layout/Loader";
import { toast } from "react-hot-toast";
import { MDBDataTable } from 'mdbreact';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import '../../App.css'; // Import the global CSS file
import MetaData from "../layout/MetaData";
import { useDispatch } from "react-redux";
import { clearCart } from "../../redux/features/cartSlice";

const MyOrders = () => {
    const { data, isLoading, error } = useMyOrdersQuery();

    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const orderSuccess = searchParams.get("order_success");

    useEffect(() => {
        if (error) {
            toast.error(error?.data?.message);
        }
        if (orderSuccess) {
            dispatch(clearCart());
            navigate("/me/orders");
        }
    }, [error, orderSuccess, dispatch, navigate]);

    const setOrders = () => {
        const orders = {
            columns: [
                {
                    label: "ID",
                    field: "id",
                    sort: "asc",
                },
                {
                    label: "Delivery Date",
                    field: "Delivery_Date",
                    sort: "asc",
                },
                {
                    label: "Delivery Time",
                    field: "Delivery_Time",
                    sort: "asc",
                },
                {
                    label: "Amount",
                    field: "amount",
                    sort: "asc",
                },
                {
                    label: "Payment Status",
                    field: "status",
                    sort: "asc",
                },
                {
                    label: "Order Status",
                    field: "orderStatus",
                    sort: "asc",
                },
                {
                    label: "Actions",
                    field: "actions",
                    sort: "asc",
                },
            ],
            rows: [],
        };

        data?.orders?.forEach((order) => {
            // Check if the delivery date has passed
            const deliveryDate = new Date(order?.shippingInfo?.deliveryDate);
            const currentDate = new Date();

            let deliveryDateText = order?.shippingInfo?.deliveryDate;

            if (deliveryDate < currentDate) {
                deliveryDateText = `Pasted Delivery: ${order?.shippingInfo?.deliveryDate}`;
            }

            orders.rows.push({
                id: order?.pid,
                Delivery_Date: deliveryDateText,
                Delivery_Time: order?.shippingInfo?.time,
                amount: `$${order?.totalAmount}`,
                status: order?.paymentInfo?.status?.toUpperCase(),
                orderStatus: order?.orderStatus,
                actions: (
                    <>
                        <Link to={`/me/order/${order?._id}`} className="btn btn-primary">
                            <i className="fa fa-eye"></i>
                        </Link>
                        <Link to={`/invoice/order/${order?._id}`} className="btn btn-success ms-2">
                            <i className="fa fa-print"></i>
                        </Link>
                    </>
                ),
            });
        });

        return orders;
    };

    if (isLoading) return <Loader />;

    return (
        <div className="container">
            <MetaData title={"My Orders"} />
            <h1 className="my-5">{data?.orders?.length} Orders</h1>
            <div className="table-responsive">
                <MDBDataTable
                    data={setOrders()}
                    className="px-3"
                    bordered
                    striped
                    hover
                />
            </div>
        </div>
    );
};

export default MyOrders;
