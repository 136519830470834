import React from 'react';

const StripePaymentError = () => {
    const styles = {
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#f5f5f5',
        },
        errorBox: {
            backgroundColor: '#ffffff',
            padding: '40px',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            textAlign: 'center',
            maxWidth: '400px',
            width: '100%',
        },
        errorTitle: {
            fontSize: '2rem',
            fontWeight: 'bold',
            color: '#e74c3c',
            marginBottom: '20px',
        },
        errorMessage: {
            fontSize: '1.2rem',
            color: '#333333',
            marginBottom: '10px',
        },
        errorInstructions: {
            fontSize: '1rem',
            color: '#555555',
            marginBottom: '20px',
        },
        button: {
            display: 'inline-block',
            padding: '10px 20px',
            margin: '5px',
            borderRadius: '5px',
            textDecoration: 'none',
            fontSize: '1rem',
            fontWeight: 'bold',
            color: '#ffffff',
            backgroundColor: '#3498db',
            transition: 'background-color 0.3s ease',
        },
        buttonHover: {
            backgroundColor: '#2980b9',
        },
        homeButton: {
            backgroundColor: '#2ecc71',
        },
        homeButtonHover: {
            backgroundColor: '#27ae60',
        }
    };

    return (
        <div style={styles.container}>
            <div style={styles.errorBox}>
                <h1 style={styles.errorTitle}>Payment Failed</h1>
                <p style={styles.errorMessage}>We're sorry, but your payment could not be processed at this time.</p>
                <p style={styles.errorInstructions}>Please check your payment details and try again.</p>
                <p style={styles.errorInstructions}>Please Contact us by phone or email for any questions.</p>
                <a
                    href="/payment_method"
                    style={{ ...styles.button, ...styles.buttonHover }}
                >
                    Retry Payment
                </a>
                <a
                    href="/home"
                    style={{ ...styles.button, ...styles.homeButton, ...styles.homeButtonHover }}
                >
                    Back to Home
                </a>
            </div>
        </div>
    );
};

export default StripePaymentError;
