import { Helmet } from "react-helmet";

const MetaData = ({ 
  title, 
  description = "Vancouver's premier destination for egg-free and dairy-free cakes and healthy desserts. Fresh, allergen-friendly baked goods made with love.",
  image = "/faviconn.png",
  schema,
}) => {
  // Base title for the website
  const baseTitle = "Vegetarian Delite Bakery";
  const fullTitle = `${title} | ${baseTitle}`;
  
  // Default schema for the bakery
  const defaultSchema = {
    "@context": "https://schema.org",
    "@type": "Bakery",
    "name": baseTitle,
    "description": description,
    "image": image,
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Vancouver",
      "addressRegion": "BC",
      "addressCountry": "CA"
    },
    "priceRange": "$$",
    "servesCuisine": [
      "Vegetarian",
      "Egg-free",
      "Dairy-free"
    ],
    "specialty": "Egg-free and dairy-free cakes",
    "keywords": "egg-free, dairy-free, vegan bakery, Vancouver bakery, healthy cakes"
  };

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{fullTitle}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content="egg-free bakery Vancouver, dairy-free cakes, vegan bakery, healthy cakes Vancouver, vegetarian desserts, allergen-friendly bakery" />
      
      {/* Favicon */}
      <link rel="icon" href="/ed.ico" type="image/x-icon" />
      <link rel="apple-touch-icon" href="/faviconn.png" />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:locale" content="en_CA" />
      
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={fullTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      
      {/* Additional SEO Tags */}
      <meta name="robots" content="index, follow" />
      <meta name="author" content="Vegetarian Delite Bakery" />
      <link rel="canonical" href={window.location.href} />
      
      {/* Schema.org Markup */}
      <script type="application/ld+json">
        {JSON.stringify(schema || defaultSchema)}
      </script>
    </Helmet>
  );
};

export default MetaData;