import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale, // Corrected from LinerScale
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2'; // Corrected from 'line'

ChartJS.register(
    CategoryScale,
    LinearScale, // Corrected from LinerScale
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);



export default function SalesChart({ salesData }) {
    const options = {
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        stacked: false,
        plugins: {
            title: {
                display: true,
                text: 'Sales & Order Data',
            },
        },
        scales: {
            y: {
                type: 'linear',
                display: true,
                position: 'left',
            },
            y1: {
                type: 'linear',
                display: true,
                position: 'right',
                grid: {
                    drawOnChartArea: false,
                },
            },
        },
    };
    
    const labels = salesData?.map((data) => data.date) || [];
    console.log(salesData);

    
    const data = {
        labels,
        datasets: [
            {
                label: 'Sales',
                data: salesData?.map((data) => data.sales),
                backgroundColor: 'rgba(42, 117, 83, 0.5)',
                borderColor: "#198753",
                yAxisID: 'y',
            },
            {
                label: 'Orders',
                data: salesData?.map((data) => data.numOrders),
                backgroundColor: 'rgba(201, 68, 82, 0.5)',
                borderColor: 'rgba(220, 52, 69)',
                yAxisID: 'y1',
            },
        ],
    };
    return <Line options={options} data={data}/>;
}
