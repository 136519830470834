import React from "react";
import SideMenu from "./SideMenu"
const UserLayout = ({ children }) => {

  const menuItems=[
    {
        name: "Profile",
        url:"/me/Profile",
        icon:"fa fa-user",
    },
    {
        name:"Update Profile",
        url:"/me/update_profile",
        icon:"fa fa-user",
    },
    {
        name:"Upload Avatar",
        url:"/me/UploadAvatar",
        icon:"fa fa-user-circle",
    },
    {
        name:"Update Password",
        url:"/me/update_password",
        icon:"fa fa-lock",
    }
];
    return (
      <div>
       {/* <div className="mt-2 mb-4 py-4">
         {/* <h2 className="text-center fw-bolder">User Settings</h2>
        </div>*/}
        <div className="container">
          <div className="row justify-content-around">
            <div className="col-12 col-lg-3">
              <SideMenu menuItems={menuItems}/>
              {/* Additional sidebar content can be added here */}
            </div>
            <div className="col-12 col-lg-9">
              {children} {/* This will render the content passed to UserLayout */}
            </div>
          </div>
        </div>
      </div>
    );
  };
export default UserLayout;