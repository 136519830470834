import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useCreateNewOrderMutation } from '../../redux/api/orderApi';
import toast from 'react-hot-toast';

const OrderForm = () => {
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);
  const [selectedOptions, setSelectedOptions] = useState({
    size: null,
    flavour: null,
    base: null,
    servings: null,
    colour: null,
  });
  const [noteToCake, setNoteToCake] = useState('');
  const [timeIn, setTimeIn] = useState('');
  const [timeOut, setTimeOut] = useState('');

  const [createOrder, { isLoading: isCreatingOrder }] = useCreateNewOrderMutation();

  const options = {
    size: [
      { name: 'Small', price: 20 },
      { name: 'Medium', price: 30 },
      { name: 'Large', price: 40 },
      { name: 'Quarter Slab', price: 50 },
      { name: 'Half Slab', price: 70 },
      { name: 'Full Slab', price: 100 }
    ],
    flavour: [
      { name: 'Vanilla', price: 5 },
      { name: 'Chocolate', price: 5 },
      { name: 'Strawberry', price: 6 },
      { name: 'Black Forest', price: 7 },
      { name: 'Red Velvet', price: 7 },
      { name: 'Fruit', price: 6 },
      { name: 'Pineapple', price: 6 },
      { name: 'Mango', price: 6 }
    ],
    base: [
      { name: 'Sponge', price: 10 },
      { name: 'Butter', price: 12 },
      { name: 'Red Velvet', price: 15 },
      { name: 'Gluten Free', price: 18 },
      { name: 'Vegan Gluten Free', price: 20 }
    ],
    servings: [
      { name: '6-8', price: 0 },
      { name: '8-10', price: 5 },
      { name: '10-15', price: 10 },
      { name: '20-25', price: 20 }
    ],
    colour: [
      { name: 'White', price: 0 },
      { name: 'Pink', price: 2 },
      { name: 'Blue', price: 2 },
      { name: 'Green', price: 2 },
      { name: 'Yellow', price: 2 }
    ]
  };

  const handleOptionSelect = (category, option) => {
    setSelectedOptions(prev => ({
      ...prev,
      [category]: prev[category]?.name === option.name ? null : option
    }));
  };

  const calculateTotal = () => {
    return Object.values(selectedOptions).reduce((total, option) => 
      total + (option ? option.price : 0), 0) * quantity;
  };

  const handlePlaceOrder = async () => {
    if (!selectedOptions.size || !selectedOptions.flavour || !selectedOptions.base || !selectedOptions.servings || !selectedOptions.colour) {
      toast.error("Please select all options before placing the order.");
      return;
    }
    
    if (!timeIn || !timeOut) {
      toast.error("Please select both time in and time out.");
      return;
    }

    const orderItem = {
      name: "Custom Cake",
      price: calculateTotal(),
      ...Object.fromEntries(Object.entries(selectedOptions).map(([key, value]) => [key, value.name])),
      noteToCake,
      quantity,
      timeIn,
      timeOut
    };

    try {
      const result = await createOrder(orderItem).unwrap();
      toast.success("Order placed successfully!");
      // Reset form or redirect user
    } catch (err) {
      toast.error(err?.data?.message || "Failed to place order. Please try again.");
    }
  };

  return (
    <div className="order-form-container">
      <h2>Custom Cake Order</h2>
      
      <div className="options-container">
        {Object.entries(options).map(([category, categoryOptions]) => (
          <div key={category} className="option-group">
            <h3>{category.charAt(0).toUpperCase() + category.slice(1)}</h3>
            <div className="option-buttons">
              {categoryOptions.map(option => (
                <button
                  key={option.name}
                  className={`option-btn ${selectedOptions[category]?.name === option.name ? 'selected' : ''}`}
                  onClick={() => handleOptionSelect(category, option)}
                >
                  {option.name} ${option.price}
                </button>
              ))}
            </div>
          </div>
        ))}
      </div>
      
      <div className="time-selection">
        <h3>Pick-up Time</h3>
        <div className="time-inputs">
          <input
            type="time"
            value={timeIn}
            onChange={(e) => setTimeIn(e.target.value)}
            placeholder="Time In"
          />
          <input
            type="time"
            value={timeOut}
            onChange={(e) => setTimeOut(e.target.value)}
            placeholder="Time Out"
          />
        </div>
      </div>

      <div className="order-summary">
        <h3>Order Summary</h3>
        <div className="quantity-control">
          <button onClick={() => setQuantity(prev => Math.max(prev - 1, 1))}>-</button>
          <span>{quantity}</span>
          <button onClick={() => setQuantity(prev => prev + 1)}>+</button>
        </div>
        <textarea
          value={noteToCake}
          onChange={(e) => setNoteToCake(e.target.value)}
          placeholder="Add a note to your cake"
        />
        <p>Total: ${calculateTotal()}</p>
        <button
          className="place-order-btn"
          onClick={handlePlaceOrder}
          disabled={!selectedOptions.size || isCreatingOrder}
        >
          {isCreatingOrder ? 'Placing Order...' : 'Place Order'}
        </button>
      </div>
    </div>
  );
};

// CSS styles
const styles = `
  .order-form-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
  }

  h2, h3 {
    color: #333;
    margin-bottom: 15px;
  }

  .options-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-bottom: 20px;
  }

  .option-group {
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  }

  .option-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .option-btn {
    padding: 8px 12px;
    border: 1px solid #007bff;
    background-color: #fff;
    color: #007bff;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .option-btn.selected {
    background-color: #007bff;
    color: #fff;
  }

  .time-selection {
    margin-bottom: 20px;
  }

  .time-inputs {
    display: flex;
    gap: 15px;
  }

  .time-inputs input {
    padding: 8px;
    border: 1px solid #ced4da;
    border-radius: 4px;
  }

  .order-summary {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  }

  .quantity-control {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
  }

  .quantity-control button {
    padding: 5px 10px;
    border: none;
    background-color: #007bff;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
  }

  textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ced4da;
    border-radius: 4px;
  }

  .place-order-btn {
    width: 100%;
    padding: 10px;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .place-order-btn:hover {
    background-color: #218838;
  }

  .place-order-btn:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
`;

export default OrderForm;