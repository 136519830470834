// cakeCal.js
const cakesData = {
    "Fruit": {
        "1001": { "6": 35, "8": 45, "quarter slab": 60, "Half slab": 90, "Full slab": 200 },
        "1002": { "6": 35, "8": 45, "quarter slab": 60, "Half slab": 90, "Full slab": 200 },
        "1003": { "6": 35, "8": 45, "quarter slab": 60, "Half slab": 90, "Full slab": 200 },
        "1004": { "6": 35, "8": 45, "quarter slab": 60, "Half slab": 90, "Full slab": 200 },
        "1005": { "6": 40, "8": 50, "quarter slab": 75, "Half slab": 105, "Full slab": 210 },
        "1006": { "6": 40, "8": 50, "quarter slab": 75, "Half slab": 105, "Full slab": 210 },
        "1007": { "6": 35, "8": 45, "quarter slab": 60, "Half slab": 90, "Full slab": 200 },
        "1008": { "6": 35, "8": 45, "quarter slab": 60, "Half slab": 90, "Full slab": 200 },
        "1009": { "6": 35, "8": 45, "quarter slab": 60, "Half slab": 90, "Full slab": 200 },
        "1010": { "6": 35, "8": 45, "quarter slab": 60, "Half slab": 90, "Full slab": 200 }
    },
    "Chocolate": {
        "1011": { "6": 40, "8": 50, "quarter slab": 75, "Half slab": 110, "Full slab": 210 },
        "1012": { "6": 40, "8": 50, "quarter slab": 75, "Half slab": 110, "Full slab": 210 },
        "CH1013": { "6": 40, "8": 50, "quarter slab": 75, "Half slab": 110, "Full slab": 210 },
        "CH1014": { "6": 40, "8": 50, "quarter slab": 75, "Half slab": 110, "Full slab": 210 },
        "CH1015": { "8": 55 }, // Mirror glaze cakes, only one size available
        "CH1016": { "8": 55 },
        "CH1017": { "8": 55 },
        "CH1018": { "8": 55 },
        "CH1019": { "8": 55 },
        "CH1020": { "8": 55 },
        "CH1021": { "6": 40, "8": 50, "quarter slab": 75, "Half slab": 110, "Full slab": 210 },
        "CH1022": { "6": 40, "8": 50, "quarter slab": 75, "Half slab": 110, "Full slab": 210 }
    },
    "Cheese cakes": {
        "C1023": { "8": 60 }, // Vanilla
        "C1024": { "8": 60 }, // Vanilla and Mango
        "C1025": { "8": 60 }, // Mango
        "C1026": { "8": 60 }, // Strawberry
        "C1027": { "8": 60 }, // Strawberry
        "C1028": { "8": 60 }, // Oreo
        "C1029": { "8": 60 }, // Oreo
        "C1030": { "8": 65 }, // Gulabjamun
        "C1031": { "8": 65 }  // Gulabjamun
    },
    "Pinata cakes": {
        "P1031": { "8": 75 }, // Comes in one size with sponge
        "P1032": { "8": 75 }  // With chocolates, comes in one size
    }
};

export const calculateCakeCost = (category, productCode, size) => {
    try {
        const price = cakesData[category][productCode][size];
        return price;
    } catch (error) {
        return null;
    }
};
